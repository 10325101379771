<template>
    <div class="box">
        <inhead></inhead>
<div style="position: fixed;top:50%;left:50%;transform: translate(-50%,-50%);width: 500px;height: 550px" v-if="!this.Android">
<logBody style="opacity: 0.85" ></logBody>
</div>

        <div style="position: fixed;top:70%;left:50%;transform: translate(-50%,-65%);width: 90%;height: 500px" v-else>
            <logBody style="opacity: 0.85"></logBody>
        </div>
        <infoot style="position: absolute;bottom: 0;left: 0;right: 0"></infoot>
    </div>
</template>

<script>
import logBody from "@/components/logUsr/logBody.vue";
import Inhead from "@/components/inhead.vue";
import Infoot from "@/components/infoot.vue";
export default {
    components:{
        Infoot,
        Inhead,
        logBody
    },
    name: "LoginPage",

    data(){
      return{

      }
    },
    created() {
        this.Android = (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent))
        console.log(this.isPc)
        const metas = document.getElementsByTagName("meta");
        metas[4].content='width=device-width,initial-scale=1'
    }
}
</script>

<style scoped>
.box {
    width: 100%;
    height: 100vh;
    background: url("~@/assets/localside.jpg");
    background-size: cover;
    background-position: center;
}

</style>